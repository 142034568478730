



































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Api } from '@/utils/api'
import app from '@/store/app'

interface DestTreeObservatory {
  id: string
  name: string
  office: string
  defaultSenderId: string
  rows: DestTreeObservatoryRow[]
}

interface DestTreeObservatoryRow {
  osid: string
  dest: DestRow
  sender: DestRow
}

interface DestRow {
  id: string
  name: string
}

@Component({
  components: {}
})
export default class extends Vue {
  dialog = false
  isAppend = false
  observatories: DestTreeObservatory[] = []
  sectionId = 'stage'
  section = 'stage'
  sections = [
    { id: 'stage', section: 'stage', name: '水位到達・水防警報（県）' },
    { id: 'mlit-stage-suibou', section: 'mlit-stage', name: '水位到達・水防警報（国）' },
    { id: 'mlit-stage-kouzui', section: 'mlit-stage', name: '洪水予報（国）' },
    { id: 'dam', section: 'dam', name: 'ダム放流通知（県）' },
    { id: 'mlit-dam', section: 'mlit-dam', name: 'ダム放流通知（国）' }
  ]
  targetRow: DestTreeObservatoryRow | null = null
  baseDestinations: DestRow[] = []
  destinations: DestRow[] = []
  filterText: string | null = null
  selectedDestId = ''
  offices: string[] = []
  office = ''
  osnames: string[] = []
  osname = ''
  selectedItemIndex = undefined
  beforeRows: DestTreeObservatoryRow[] = []

  created(): void {
    this.sync()
  }

  mounted(): void {
    //
  }

  @Watch('office')
  changeOffice(): void {
    this.osnames = this.observatories
      .filter((obs: DestTreeObservatory) => obs.office === this.office)
      .map((obs: DestTreeObservatory) => obs.name)
    this.osnames.unshift('')
    this.osname = ''
  }

  async sync(): Promise<void> {
    this.section = this.sections.find((s: any) => s.id === this.sectionId)?.section || 'stage'
    this.baseDestinations = []
    this.destinations = []
    this.observatories = []
    this.osname = ''
    const res = await Api.get(`/destination-tree/${this.section}`)
    if (res === null) {
      return
    }
    this.baseDestinations = res.destinations.concat()
    res.observatories.forEach((obs: DestTreeObservatory) => {
      const target = Object.assign({}, obs)
      if (this.sectionId === 'mlit-stage-suibou' && target.id.startsWith('KY_')) {
        return
      }
      if (this.sectionId === 'mlit-stage-kouzui' && !target.id.startsWith('KY_')) {
        return
      }
      this.observatories.push(target)
    })

    // offices
    this.offices = this.observatories.map((obs: DestTreeObservatory) => obs.office)
    this.offices = Array.from(new Set(this.offices))
    this.office = this.offices[0]
  }

  async syncDestinations(): Promise<void> {
    const res = await Api.get(`/destination-tree/${this.section}/dests`)
    if (res === null) {
      return
    }
    this.baseDestinations = res.concat()
    this.filterDests()
  }

  filterDests(target: string | undefined = undefined): void {
    if (target !== undefined) {
      this.filterText = target
    }
    const text = this.filterText === null ? '' : this.filterText
    const destinations: DestRow[] = []

    // 対象外の宛先ID
    let excludeIds: string[] = []
    if (this.targetRow !== null) {
      if (this.isAppend === true) {
        const finded = this.observatories.find((obs: DestTreeObservatory) => obs.id === this.targetRow?.osid)
        if (finded !== undefined) {
          excludeIds = finded.rows.map((row: DestTreeObservatoryRow) => row.dest.id)
        }
      } else {
        excludeIds.push(this.targetRow.dest.id)
      }
    }
    this.baseDestinations.forEach((d: DestRow) => {
      if (excludeIds.includes(d.id) === false && (text === '' || d.name.includes(text))) {
        destinations.push({
          id: d.id,
          name: d.name
        })
      }
    })

    destinations.sort((a, b) => (isNaN(Number(a.id)) ? 0 : Number(a.id)) - (isNaN(Number(a.id)) ? 0 : Number(b.id)))
    this.destinations = destinations
    this.selectedItemIndex = undefined
    this.selectedDestId = ''
  }

  add(osid: string): void {
    const row: DestTreeObservatoryRow = {
      osid: osid,
      dest: { id: '', name: '' },
      sender: { id: '', name: '' }
    }
    this.edit(row)
  }

  edit(row: DestTreeObservatoryRow): void {
    this.isAppend = row.dest.id === ''
    this.targetRow = row
    this.filterDests('')
    this.dialog = true
  }

  getDestName(id: string): string {
    const finded = this.baseDestinations.find((d: DestRow) => d.id === id)
    return finded === undefined ? '' : finded.name
  }

  async save(): Promise<void> {
    if (this.targetRow === null || this.selectedDestId === '') {
      return
    }
    const selected: DestRow = {
      id: this.selectedDestId,
      name: this.getDestName(this.selectedDestId)
    }
    const obs = this.observatories.find((obs: DestTreeObservatory) => obs.id === this.targetRow?.osid)
    if (obs === undefined) {
      return
    }
    this.beforeRows = obs.rows.concat()
    if (this.isAppend === true) {
      let defSenderId = this.section === 'mlit-stage' ? '16' : this.section === 'mlit-dam' ? '3' : obs.defaultSenderId
      if (selected.id === defSenderId) {
        defSenderId = obs.defaultSenderId
      }
      obs.rows.push({
        osid: obs.id,
        dest: selected,
        sender: {
          id: defSenderId,
          name: this.getDestName(defSenderId)
        }
      })
    } else {
      const findedRow = obs.rows.find((r: DestTreeObservatoryRow) => r.dest.id === this.targetRow?.dest.id)
      if (findedRow !== undefined) {
        findedRow.sender = selected
      }
    }
    await this.updateObservatoryRows(obs)
    this.dialog = false
  }

  async deleteRow(row: DestTreeObservatoryRow): Promise<void> {
    if (confirm(`${row.dest.name}を連絡系統から除外します。\nよろしいですか？`) !== true) {
      return
    }
    const obs = this.observatories.find((obs: DestTreeObservatory) => obs.id === row.osid)
    if (obs === undefined) {
      return
    }
    this.beforeRows = obs.rows.concat()
    obs.rows = obs.rows.filter((r: DestTreeObservatoryRow) => r.dest.id !== row.dest.id)
    await this.updateObservatoryRows(obs)
  }

  async updateObservatoryRows(obs: DestTreeObservatory): Promise<void> {
    const params = {
      section: this.section,
      osid: obs.id,
      rows: obs.rows
    }
    const res = await Api.post(`/destination-tree/update`, params)
    if (res === null) {
      app.setSnackbar('更新に失敗しました')
      obs.rows = this.beforeRows.concat()
    }
  }
}
