










































































import { MlitSection, MlitObservatory } from '@/types'
import { Common, Consts, Api } from '@/utils'
import { Component, Vue, Watch } from 'vue-property-decorator'
import mlit from '@/store/mlit'

@Component({
  components: {}
})
export default class MlitTop extends Vue {
  rows: any[] = []
  offices: string[] = []
  osnames: string[] = []
  searchItem = {
    office: '',
    osname: '',
    category: ''
  }
  observatories: MlitObservatory[] = []

  get section(): MlitSection {
    return this.$route.params.section === 'dam' ? 'dam' : 'stage'
  }

  async created(): Promise<void> {
    await this.initSection()
    await this.sync()
  }

  @Watch('section')
  async changeSection(): Promise<void> {
    await this.initSection()
    await this.sync()
  }

  async initSection(): Promise<void> {
    this.observatories = await mlit.fetch(this.section)
    this.offices = ['']
    this.osnames = ['']
    this.observatories.forEach((os: MlitObservatory) => {
      if (this.offices.includes(os.office) === false) {
        this.offices.push(os.office)
      }
      this.osnames.push(os.name)
    })
    this.searchItem = {
      office: '',
      osname: '',
      category: ''
    }
  }

  async sync(): Promise<void> {
    const params = {
      office: this.searchItem.office,
      osname: this.searchItem.osname
    }
    let rows = await Api.get(`/mlit/list/${this.section}`, params)
    if (this.searchItem.category !== '') {
      rows = rows.filter((row: any) => {
        const isKouzui = row.subject.includes('洪水予報')
        if (this.searchItem.category === '洪水予報') {
          return isKouzui
        } else {
          return !isKouzui
        }
      })
    }
    rows.forEach((row: any) => {
      row.color = Common.getStageLevelColor('standby')
    })
    this.rows = rows
  }

  changeOffice(): void {
    this.searchItem.osname = ''
    this.sync()
  }

  changeCategory(): void {
    this.searchItem.osname = ''
    this.sync()
  }

  changeOsname(): void {
    const finded = this.observatories.find((obs: MlitObservatory) => obs.name === this.searchItem.osname)
    if (finded !== undefined) {
      this.searchItem.office = finded.office
    }
    this.sync()
  }

  forwarded(row: { [key: string]: string }): boolean {
    return row.forwardDatetime !== undefined && row.forwardDatetime !== ''
  }

  preview(key: string): void {
    if (key === undefined || key === '') {
      return
    }
    const url = `${Consts.S3_PUBLIC_URL}/${key}`
    window.open(url, '_blank')
  }
}
